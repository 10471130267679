import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface CustomModalProps {
  show: boolean;
  handleClose: () => void;
  message: string;
  primaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonText: string;
  secondaryButtonAction: () => void;
  primaryButtonVariant?: 'primary' | 'danger';
  AnimationComponent?: React.FC;
  primaryButtonIcon?: React.ReactNode;
  secondaryButtonIcon?: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  show,
  handleClose,
  message,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonText,
  secondaryButtonAction,
  primaryButtonVariant = 'primary',
  AnimationComponent,
  primaryButtonIcon,
  secondaryButtonIcon,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" className="custom-modal">
      <Modal.Body className="text-center">
        {AnimationComponent && <AnimationComponent />}
        <div className="mt-3">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col xs={6} className="d-flex justify-content-start">
            <Button variant="secondary" onClick={secondaryButtonAction} className="w-100">
              {secondaryButtonIcon && <span className="mr-2">{secondaryButtonIcon}</span>}
              {secondaryButtonText}
            </Button>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <Button variant={primaryButtonVariant} onClick={primaryButtonAction} className="w-100">
              {primaryButtonIcon && <span className="mr-2">{primaryButtonIcon}</span>}
              {primaryButtonText}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
