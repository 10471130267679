import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

interface InfoCardProps {
    images?: { src: string; alt: string }[];
    image?: { src: string; alt: string };
    children: React.ReactNode;
}

const InfoCard: React.FC<InfoCardProps> = ({ images = [], image = { src: '', alt: '' }, children }) => {
  const renderImages = (images: { src: string; alt: string }[]) => {
    if (images.length === 0 && image.src) {
      return <Card.Img className="p-4" src={image.src} alt={image.alt} />;
    }
    return images.map((img, index) => (
      <Card.Img key={index} className="p-4" src={img.src} alt={img.alt} />
    ));
  };

  return (
    <Container className="my-5">
      <Row className="mb-4">
        <Col>
          <Card>
            <Row className="g-0">
              <Col md={4} className="align-content-center">
                {renderImages(images)}
              </Col>
              <Col md={8}>
                <Card.Body>
                  {children}
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoCard;
