import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "typeface-roboto";
import "./styles/index.scss";
import "./i18n";
import { routes } from "./routes";
import { Navigation } from "components";
import PageContainer from "components/Shared/Page/PageContainer";
import CustomFooter from "components/Footer/CustomFooter";
import { SearchProvider } from "components/Search/SearchContext";
import ProductDetails from "components/Products/ProductDetails";
import axios from "axios";
import { IProduct } from "types/productTypes";
import { Container } from "react-bootstrap";
import NotFound from "components/Shared/Errors/NotFound";
import { Provider } from "react-redux";
import TopBar from "components/TopBar/TopBar";
import store from "store/store";
import ReactGA from 'react-ga4';


const App = () => {
  ReactGA.initialize('G-SF4BD02583');
  const [products, setProducts] = useState<IProduct[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/api/multi_search/`
        );
        setProducts(data);
      } catch (err) {
        setError("Failed to fetch data.");
        console.error("Failed to fetch data", err);
      }
    };

    fetchData();
  }, []);

  return (
    <Provider store={store}>
      <SearchProvider>
        <Router>
          <RouteChangeTracker />
          {error && <Container>{error}</Container>}
          <div className="fixed-top">
            <TopBar />
            <Navigation />
          </div>
          <PageContainer>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
              <Route
                path="/product/:id"
                element={<ProductDetails products={products} />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </PageContainer>
          <CustomFooter />
        </Router>
      </SearchProvider>
    </Provider>
  );
};

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

const root = document.getElementById("root") ?? undefined;

if (root) {
  createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}
