import React from "react";
import { connect, ConnectedProps } from "react-redux";
import ProductCard from "./ProductCard";
import { IProduct } from "types/productTypes";
import { RootState } from "store/types";

interface ProductListProps {
  products: IProduct[];
}

const mapStateToProps = (state: RootState) => ({
  cart: state.cart.items,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type CombinedProps = ProductListProps & PropsFromRedux;

const ProductsList: React.FC<CombinedProps> = ({ products, cart }) => (
  <div className="grid-container">
    {products.map((product, index) => (
      <ProductCard key={index} details={product} cart={cart} />
    ))}
  </div>
);

export default connector(ProductsList);
