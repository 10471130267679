import { HierarchicalLinkItem } from "types/linkTypes";
import { buildLinkItem, createServiceItems } from "utils/keyUtils";

export const LINK_ITEMS: HierarchicalLinkItem[] = [
  buildLinkItem("home"),
  buildLinkItem("services.carpets", [
    buildLinkItem("services.carpets.persian"),
    buildLinkItem("services.carpets.oriental"),
    buildLinkItem("services.carpets.kilim"),
  ]),
  ...[
    "services.washingRestoration",
    "services.bagsPillows",
    "contacts",
    "aboutUs",
    "services.sales",
  ].map((key) => buildLinkItem(key)),
];

export const SERVICES: HierarchicalLinkItem[] = createServiceItems(
  LINK_ITEMS.slice(1, 4)
);
