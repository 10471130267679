import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/Shared/Loading/LoadingSpinner';
import PaymentInformation from './PaymentInformation';
import PriceDetails from 'components/Order/PriceDetails';
import { getOrderId } from 'api/orderService';
import { fetchCartDetails } from 'store/cartSlice';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'store/types';
import NotFound from 'components/Shared/Errors/NotFound';

const mapStateToProps = (state: RootState) => ({
    cartLoading: state.cart.loading,
    cart: state.cart.items,
});

const mapDispatchToProps = {
    fetchCartDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type CheckoutProps = PropsFromRedux;

const Checkout: React.FC<CheckoutProps> = ({ cart, cartLoading, fetchCartDetails }) => {
    const [orderState, setOrderState] = useState<{ id: number | null; loading: boolean; error: string | null }>({
        id: null,
        loading: true,
        error: null,
    });

    const { t } = useTranslation();
    const paymentDetails = {
        IBAN: 'IT16B0306234210000002272963',
        paypalEmail: 'saidtappeti@gmail.com',
    };

    useEffect(() => {
        const fetchOrderId = async () => {
            try {
                const data = await getOrderId();
                setOrderState({ id: data, loading: false, error: null });
            } catch (err) {
                setOrderState({ id: null, loading: false, error: err instanceof Error ? err.message : 'An unexpected error occurred' });
            }
        };

        fetchOrderId();
        fetchCartDetails();
    }, [fetchCartDetails]);

    if (orderState.loading || cartLoading) return <LoadingSpinner />;
    if (orderState.error) return <div>Error: {orderState.error}</div>;
    if (cart.length === 0) return <NotFound />;

    return (
        <>
            <h1 className="mb-4">{`${t('order.thankYou')} ${t('order.oneLastStep')}`}</h1>
            <h4 className="mb-4">{t('order.completeOrder')}</h4>
            
            <PaymentInformation 
                orderId={orderState.id} 
                IBAN={paymentDetails.IBAN} 
                paypalEmail={paymentDetails.paypalEmail} 
            />

            <PriceDetails cart={cart} />
        </>
    );
};

export default connector(Checkout);
