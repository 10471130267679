import React from 'react';
import CustomModal from './CustomModal';
import { useTranslation } from 'react-i18next';
import { Success } from '../Success/Success';
import { FaArrowLeft, FaShoppingCart } from 'react-icons/fa';

interface AddToCartModalProps {
  show: boolean;
  handleClose: () => void;
}

const AddToCartModal: React.FC<AddToCartModalProps> = ({ show, handleClose }) => {
  const { t } = useTranslation();

  return (
    <CustomModal
      show={show}
      handleClose={handleClose}
      message={t('cart.product.addedToCartMessage')}
      primaryButtonText={t('cart.goToCart')}
      primaryButtonAction={() => window.location.href = "/#/carrello"}
      secondaryButtonText={t('cart.continueShopping')}
      secondaryButtonAction={handleClose}
      AnimationComponent={() => <Success />}
      primaryButtonIcon={<FaShoppingCart />}
      secondaryButtonIcon={<FaArrowLeft />}
    />
  );
};

export default AddToCartModal;
