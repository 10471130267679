import React from "react";
import { useTranslation } from "react-i18next";
import Page from "./Page";

const AboutUsPage: React.FC = () => {
  const { t } = useTranslation();

  const description = t("aboutUs.longDescription")
    .split("||")
    .map((line) => <p>{line}</p>);

  return (
    <Page>
      <img
        className="fullscreen-image big-screen-image"
        src="../images/aboutUs/aboutUs.jpg"
        alt="About us background"
      ></img>
      <img
        className="fullscreen-image small-screen-image"
        src="../images/aboutUs/aboutUs2.jpg"
        alt="About us background"
      ></img>
      <div className="overlay-content p-5">
        <h1>{t("aboutUs.title")}</h1>
        <div className="py-5">{description}</div>
      </div>
    </Page>
  );
};

export default AboutUsPage;
