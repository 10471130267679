import React from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../components/Shared/Page/PageHeader";
import WashingService from "components/Washing/WashingService";
import RestorationService from "components/Restoration/RestorationService";
import Page from "./Page";

const WashingRestorationPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader
        title={t("services.washingRestoration.title")}
        description={t("services.washingRestoration.description")}
        image="../images/services/washingRestoration2.jpg"
      />
      <WashingService />
      <RestorationService />
    </Page>
  );
};

export default WashingRestorationPage;
