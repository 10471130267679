import HomePage from "pages/HomePage";
import ContactsPage from "pages/ContactsPage";
import BagsPillowsPage from "pages/BagsPillowsPage";
import AboutUsPage from "pages/AboutUsPage";
import SearchResultsPage from "pages/SearchResultsPage";
import {
  PersianCarpetsPage,
  KilimsPage,
  OrientalCarpetsPage,
} from "pages/CarpetsPages";
import WashingRestorationPage from "pages/WashingRestorationPage";
import SalesPage from "pages/SalesPage";
import CartPage from "pages/CartPage";
import CheckoutPage from "pages/CheckoutPage";
import ClientDetailsPage from "pages/ClientDetailsPage";

export const routes = [
  { path: "/", component: HomePage },
  { path: "/contatti", component: ContactsPage },
  { path: "/lavaggioRestauro", component: WashingRestorationPage },
  { path: "/kilim", component: KilimsPage },
  { path: "/tappetiPersiani", component: PersianCarpetsPage },
  { path: "/tappetiOrientali", component: OrientalCarpetsPage },
  { path: "/borseCuscini", component: BagsPillowsPage },
  { path: "/chiSiamo", component: AboutUsPage },
  { path: "/risultatiRicerca", component: SearchResultsPage },
  { path: "/sconti", component: SalesPage },
  { path: "/carrello", component: CartPage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/dettagliCliente", component: ClientDetailsPage },
];
