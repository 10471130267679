import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IProduct } from "types/productTypes";
import { renderPrice } from "../../utils/priceUtils";
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css';
import { FaCartPlus, FaCheck } from "react-icons/fa";
import { addToCart } from "api/cartService";
import AddToCartModal from "components/Shared/Modals/AddToCartModal";
import LoadingSpinner from "components/Shared/Loading/LoadingSpinner";
import ScrollReveal from "components/Shared/ScrollReveal/ScrollReveal";
import { fetchCartDetails } from "store/cartSlice";
import { useDispatch, connect, ConnectedProps } from "react-redux";
import { scrollToTop } from "utils/navigationUtils";
import { AppDispatch } from "store/store";
import { RootState } from "store/types";
import { set } from "react-ga";

interface Props {
  products: IProduct[];
}

const mapStateToProps = (state: RootState) => ({
  cart: state.cart.items,
  loading: state.cart.loading,
});

const mapDispatchToProps = {
  fetchCartDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type CombinedProps = Props & PropsFromRedux;

const ProductDetails: React.FC<CombinedProps> = ({ products, cart, loading, fetchCartDetails }) => {
  const { id } = useParams<{ id?: string }>();
  const [addLoading, setAddLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [product, setProduct] = useState<IProduct | undefined>(undefined);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    scrollToTop();

    if (id) {
      const productId = parseInt(id);
      const foundProduct = products.find((p) => p.id === productId);
      setProduct(foundProduct);
    }
    
    setAddLoading(false);
  }, [id, products]);

  const handleAddToCart = async (id: number) => {
    try {
      setAddLoading(true);
      await addToCart(id);
      setShowModal(true);
      setAddLoading(false);
      setAdded(true);
    } catch (error) {
      console.error('There was an error adding the item to the cart!', error);
      setAddLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (loading || !product) {
    return (
      <Container className="text-center my-5">
        <LoadingSpinner />
      </Container>
    );
  }

  const isInCart = cart.some(item => item.id === product?.id);

  return (
    <ScrollReveal>
      <Container className="mt-5">
        <Row>
          <Col xs={12} md={7} className="text-center my-2 p-5">
            <ProductImage src={product.photo_url} alt="Product" />
            {product.second_photo_url && (
              <ProductImage src={product.second_photo_url} alt="Product Second View" />
            )}
          </Col>
          <Col xs={12} md={5} className="p-5 align-content-center">
            <div className="product-info">
              <h2 className="font-weight-bold">{product.name.toUpperCase()}</h2>
              <p>{renderPrice(product.price, product.sale)}</p>
              <ProductAttributes product={product} />
              <div className="mt-5">
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={() => handleAddToCart(product?.id || 0)}
                  disabled={isInCart || added || addLoading}
                >
                  {addLoading ? (
                    <LoadingSpinner size={24} centerOfPage={false} />
                  ) : isInCart || added ? (
                    <FaCheck className="mr-2" />
                  ) : (
                    <FaCartPlus className="mr-2" />
                  )} 
                  {t(isInCart || added ? "cart.added" : "cart.add")}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <AddToCartModal show={showModal} handleClose={handleCloseModal} />
      </Container>
    </ScrollReveal>
  );
};

const ProductImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <div className="my-2">
    <Zoom>
      <img className="product-details-img" src={src} alt={alt} />
    </Zoom>
  </div>
);

const ProductAttributes: React.FC<{ product: IProduct }> = ({ product }) => {
  const { t } = useTranslation();
  const attributes = [
    { title: t("product.referenceNumber"), value: product.reference_number || "N/A" },
    { title: t("product.size"), value: `${product.height} x ${product.width} cm` },
    { title: t("product.description"), value: product.description },
  ];

  return (
    <div>
      {attributes.map((attr, index) => (
        <Attribute key={index} title={attr.title} value={attr.value} />
      ))}
    </div>
  );
};

const Attribute: React.FC<{ title: string; value: string }> = ({ title, value }) => (
  <div className="my-2">
    <div className="font-weight-bold text-primary">{title.toUpperCase()}</div>
    <div>{value}</div>
  </div>
);

export default connector(ProductDetails);
