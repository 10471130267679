import React from 'react';
import { IProduct } from 'types/productTypes';
import { useTranslation } from 'react-i18next';
import { calculatePriceAfterDiscount, calculateTotal } from 'utils/priceUtils';

interface PriceDetailsProps {
  cart: IProduct[];
}

const PriceDetails: React.FC<PriceDetailsProps> = ({ cart }) => {
  const { t } = useTranslation();
  const totalBeforeDiscount = calculateTotal(cart);
  const discount = totalBeforeDiscount - cart.reduce((total, item) => total + calculatePriceAfterDiscount(item.price, item.sale), 0);
  const shipping = 0;
  const totalAfterDiscount = totalBeforeDiscount - discount + shipping;

  return (
    <section className="my-4 gray-bg p-4 rounded">
      <h2 className="mb-4">{t('cart.priceDetails')}</h2>
      <div className="price-detail-item mb-3">
        <strong className="me-auto">{t('cart.beforeSales')}:</strong>
        <span>{totalBeforeDiscount} €</span>
      </div>
      <div className="price-detail-item mb-3">
        <strong className="me-auto">{t('cart.discount')}:</strong>
        <span>{discount > 0 ? `- ${discount} €` : '-'}</span>
      </div>
      <div className="price-detail-item mb-3">
        <strong className="me-auto">{t('cart.shipping')}:</strong>
        <span>{shipping > 0 ? `${shipping} €` : t('cart.free')}</span>
      </div>
      <div className="border-top pt-3 price-detail-item">
        <strong className="me-auto">{t('cart.total')}:</strong>
        <span><h5>{totalAfterDiscount} €</h5></span>
      </div>
    </section>
  );
};

export default PriceDetails;
