import { FetchResponse, fetchApi } from "utils/apiUtils";

const BACKEND_BASE_URL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/cart/`;
const apiUrl = (endpoint: string) => `${BACKEND_BASE_URL}${endpoint}/`;

export const addToCart = (productId: number): Promise<FetchResponse<any>> => {
  return fetchApi(apiUrl(`add/${productId}`), { method: "POST" });
};

export const removeFromCart = (productId: number): Promise<FetchResponse<any>> => {
  return fetchApi(apiUrl(`remove/${productId}`), { method: "POST" });
};

export const getCartDetails = (): Promise<FetchResponse<any>> => {
  return fetchApi(apiUrl('detail'), { method: "GET" });
};
